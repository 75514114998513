import { Component, ElementRef, OnInit, viewChildren } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { TranslateModule } from '@ngx-translate/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  FhirQuestionnaire,
  FhirQuestionnaireResponse,
  QuestionnaireRendererComponent,
} from '@client-workspace/fhir-engine'
import { fromEvent, map } from 'rxjs'
import { NavigationPanelComponent } from '../navigation-panel/navigation-panel.component'

export interface Bogenpaket {
  id: string
  name: string
  forms: Array<Bogen>
  packets?: string
}
interface Bogen {
  id: string
  name: string
  language: string
}

@Component({
  selector: 'app-new-admissions',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    QuestionnaireRendererComponent,
    NavigationPanelComponent,
  ],
  templateUrl: './new-admissions.component.html',
  styleUrl: './new-admissions.component.scss',
})
export class NewAdmissionsComponent implements OnInit {
  bogenpakets: Bogenpaket[] = []
  bogenpaket: Bogenpaket | undefined = undefined
  admissions: Array<FhirQuestionnaire> = []
  selectedPaketId = ''
  isStartDisabled = true
  questionnaireResponse: FhirQuestionnaireResponse | undefined = undefined
  person = { firstName: '', lastName: '', birthDay: '' }

  finishedFormIds: Array<string> = [] //'E-EDPXX-0000000000']
  currentFormIds: Array<string> = [] //'E-EDP9-0010006558']

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.person.firstName = params['fn']
      this.person.lastName = params['ln']
      this.person.birthDay = /^\d{2}\.\d{2}\.\d{4}$/.test(params['bd']) ? params['bd'] : ''
    })
  }

  ngOnInit(): void {
    if (!this.person.firstName || !this.person.lastName || !this.person.birthDay) {
      this.router.navigateByUrl('/admissions/unknown', { state: { person: this.person } })
    }

    this.loadBogenpakets()
  }

  async loadBogenpakets() {
    try {
      const response = await fetch(`/services/content/forms/packages`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()

      this.bogenpakets = data.packages
      this.bogenpakets?.forEach((bogen) => {
        bogen.packets = bogen.forms.map((form) => form.name).join(', ')
      })
    } catch (error) {
      console.log(error)
    }
  }

  handleChange(checked: boolean, bogenpaket: Bogenpaket) {
    this.selectedPaketId = checked ? bogenpaket.id : ''
    this.isStartDisabled = !this.selectedPaketId
  }

  async startAdmission() {
    this.bogenpaket = this.bogenpakets.find((bogen) => bogen.id === this.selectedPaketId)
    if (!this.bogenpaket) {
      return
    }

    const formIds = this.bogenpaket.forms.map((form) => form.id)
    const fetches = formIds.map((id) =>
      fetch(`/services/content/forms/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    )

    Promise.all(fetches)
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then((data) => {
        this.admissions = data
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // Implemenet current-form indicator
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && !this.currentFormIds.includes(entry.target.id)) {
                this.currentFormIds.push(entry.target.id)
              }
              if (!entry.isIntersecting) {
                this.currentFormIds = this.currentFormIds.filter((id) => id !== entry.target.id)
              }
            })
          },
          { rootMargin: '0px', threshold: 0.01 },
        )

        setTimeout(() => {
          this.elementRef.nativeElement.querySelectorAll('.scroll-anchor').forEach((questionElement: HTMLElement) => {
            if (questionElement) {
              observer.observe(questionElement)
            }
          })
        }, 0)

        // Implement scroll indicator
        const listWrapper = this.elementRef.nativeElement.querySelector('.admission-list')
        if (listWrapper) {
          fromEvent(listWrapper, 'scroll')
            .pipe(
              map(() => ({
                height: listWrapper.scrollHeight - listWrapper.offsetHeight,
                scrollY: listWrapper.scrollTop,
              })),
            )
            .subscribe(({ height, scrollY }) => {
              const perc = (scrollY / height) * 100
              this.elementRef.nativeElement.style.setProperty('--scroll-percentage', `${perc}%`)
            })
        }
      })
  }

  navigateToForm(id: string) {
    this.elementRef.nativeElement.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
  }

  updateQuestionnaireResponse(updatedQuestionnaireResponse: FhirQuestionnaireResponse) {
    // console.log(updatedQuestionnaireResponse)
  }

  handleQuestionnaireCompletenessChange({ id, completed }: { id: string; completed: boolean }) {
    if (completed && !this.finishedFormIds.includes(id)) {
      this.finishedFormIds.push(id)
    }

    if (!completed && this.finishedFormIds.includes(id)) {
      this.finishedFormIds.splice(this.finishedFormIds.indexOf(id), 1)
    }
  }
}
