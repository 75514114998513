import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActiveQuestionnaireItem } from '@client-workspace/fhir-engine'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'fhir-questionnaire-item-type-string',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './questionnaire-item-type-string.component.html',
  styleUrl: './questionnaire-item-type-string.component.scss',
})
export class QuestionnaireItemTypeStringComponent implements OnInit {
  @Input() questionnaireItem!: ActiveQuestionnaireItem
  @Output() questionnaireItemChange = new EventEmitter<string | null>()
  formControl: FormControl<string | Date | null> = new FormControl('')

  ngOnInit() {
    const value = this.questionnaireItem.answer?.getAnswer() as string
    value && this.formControl.setValue(value)
  }
}
