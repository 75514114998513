import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { EnableWhen, QuestionnaireItem } from '../../questionnaire-builder/models/fhir-interfaces'

@Injectable({
  providedIn: 'root',
})
export class EnableWhenService {
  private enableWhenMap = new Map<string, EnableWhen[]>()
  private answers = new Map<string, string>()
  private visibilitySubject = new BehaviorSubject<{ [key: string]: boolean }>({})
  visibilityChanges$ = this.visibilitySubject.asObservable()

  initialize(questionnaireItems: QuestionnaireItem[]): void {
    questionnaireItems.forEach((item: QuestionnaireItem) => {
      if (item.enableWhen && item.enableWhen.length) {
        this.enableWhenMap.set(item.linkId, item.enableWhen)
      }
    })
  }

  updateAnswer(linkId: string, newValue: string): void {
    this.answers.set(linkId, newValue)
    this.checkVisibility()
  }

  private checkVisibility(): void {
    const visibility: { [key: string]: boolean } = {}

    this.enableWhenMap.forEach((rules, linkId) => {
      visibility[linkId] = rules.every((rule: EnableWhen) => {
        const answer = this.answers.get(rule.question)
        if (answer && rule.answerString) {
          return this.evaluateCondition(answer, rule.operator, rule.answerString)
        }
        return
      })
    })

    this.visibilitySubject.next(visibility)
  }

  private evaluateCondition(answer: string, operator: string, expected: string): boolean {
    switch (operator) {
      case 'exists':
        return answer !== undefined
      case '=':
        return answer === expected
      case '!=':
        return answer !== expected
      case '>':
        return parseFloat(answer) > parseFloat(expected)
      case '<':
        return parseFloat(answer) < parseFloat(expected)
      case '<=':
        return parseFloat(answer) <= parseFloat(expected)
      case '>=':
        return parseFloat(answer) >= parseFloat(expected)
      default:
        return false
    }
  }
}
