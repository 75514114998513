export const fhirQuestionnaireItemTypes = {
  language: 'de-DE',
  resourceType: 'Questionnaire',
  status: 'draft',
  name: '',
  title: 'FHIR Questionnaire Item Types - Overview',
  item: [
    {
      linkId: 'group1',
      type: 'group',
      text: 'Group 1',
      item: [
        {
          linkId: 'display1',
          text: 'Display 1 in top level Group',
          type: 'display',
        },
        {
          linkId: 'string1',
          text: 'String 1 in top level Group',
          type: 'string',
        },
        {
          linkId: 'question1',
          text: 'Question 1 in top level Group',
          type: 'question',
        },
        {
          linkId: 'text1',
          text: 'Text 1 in top level Group',
          type: 'text',
        },
        {
          linkId: 'groupGroup1',
          text: 'Sub Group 1 Date & Time',
          type: 'group',
          item: [
            {
              linkId: 'date1',
              text: 'Date 1 in top level Group',
              type: 'date',
            },
            {
              linkId: 'time1',
              text: 'Time 1 in top level Group',
              type: 'time',
            },
          ],
        },
        {
          linkId: 'groupGroup2',
          text: 'Sub Group 2 - DateTime',
          type: 'group',
          item: [
            {
              linkId: 'dateTime1',
              text: 'dateTime 1 in top level Group',
              type: 'dateTime',
            },
          ],
        },
        {
          linkId: 'boolean1',
          text: 'Boolean 1 in top level Group',
          type: 'boolean',
        },
        {
          linkId: 'choice1',
          text: 'Choice 1 in top level Group',
          type: 'choice',
          answerOption: [
            {
              id: 'cA',
              valueCoding: {
                code: '',
                display: 'Choice A',
                system: 'choiceA',
              },
            },
            {
              id: 'cB',
              initialSelected: true,
              valueCoding: {
                display: 'Choice B',
                system: 'choiceB',
              },
            },
          ],
        },
        {
          linkId: 'choice2',
          text: 'Repeatable Choice 2 in top level Group with very long displays',
          type: 'choice',
          repeats: true,
          answerOption: [
            {
              id: 'cE',
              valueCoding: {
                code: '',
                display: 'Choice E - Lorem Ipsum dolor sit amet, consectetur adipiscing elit.',
                system: 'choiceE',
              },
            },
            {
              id: 'cF',
              initialSelected: true,
              valueCoding: {
                display: 'Choice F - Lorem Ipsum dolor sit amet, consectetur adipiscing elit.',
                system: 'choiceF',
              },
            },
          ],
        },
      ],
    },
    {
      linkId: 'group2',
      type: 'group',
      text: '',
      item: [
        {
          linkId: 'display3',
          text: 'Display 3 in top level Group without text',
          type: 'display',
        },
      ],
    },
    {
      linkId: 'display2',
      text: 'Display 2 in top level',
      type: 'display',
    },
    {
      linkId: 'choice2',
      text: 'Choice 2 in top level Group',
      type: 'choice',
      answerOption: [
        {
          id: 'cC',
          valueCoding: {
            code: '',
            display: 'Choice C',
            system: 'choiceC',
          },
        },
        {
          id: 'cD',
          initialSelected: true,
          valueCoding: {
            display: 'Choice D',
            system: 'choiceD',
          },
        },
      ],
    },
  ],
}
