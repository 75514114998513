import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { TranslateModule } from '@ngx-translate/core'
import { v4 as uuidv4 } from 'uuid'

export type ButtonElementOption = {
  value: string
  label: string
  disabled?: boolean
  checked?: boolean
}

@Component({
  selector: 'cc-toggle-buttons',
  standalone: true,
  imports: [ReactiveFormsModule, LabelComponent, TranslateModule],
  templateUrl: './toggle-buttons.component.html',
  styleUrl: './toggle-buttons.component.scss',
})
export class ToggleButtonsComponent {
  @Input() label = ''
  @Input() value!: string | undefined
  @Input() optional?: boolean = false
  @Input() buttonElements: ButtonElementOption[] = []
  @Output() valueChange = new EventEmitter<string>()

  uuid = uuidv4()
  selectedOption: string | undefined = undefined
  groupId: string = uuidv4()

  onSelectionChange(e: Event) {
    const target = e.target as HTMLInputElement

    if (this.optional && this.selectedOption === target.value) {
      this.selectedOption = undefined
      target.checked = false
    } else {
      this.selectedOption = target.value
    }
    this.value = this.selectedOption
    this.valueChange.emit(this.value)
  }
}
