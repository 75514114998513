export const einwilligungDigitalisierung = {
  resourceType: 'Questionnaire',
  id: 'E-EDP2-0010006558',
  meta: {
    profile: ['http://thieme-compliance.de/fhir/StructureDefinition/Questionnaire'],
  },
  language: 'de-DE',
  text: {
    status: 'generated',
    div: '<h:div xml:lang="de-DE" xmlns:h="http://www.w3.org/1999/xhtml"><h:h1>EDP 2_0010006558 – Digitalisierung Kommunikation</h:h1><h:table><h:thead><h:tr><h:th>Nr.</h:th><h:th>Frage</h:th><h:th>Antwort</h:th></h:tr></h:thead><h:tbody><h:tr><h:td><h:b /></h:td><h:td><h:b>Einwilligung zur Digitalisierung Ihrer Telefonnummer und E-Mail-Adresse</h:b></h:td><h:td /></h:tr><h:tr><h:td></h:td><h:td>Ich willige in die Verwendung meiner Telefonnummer und E-Mail-Adresse für digitale Kommunikationszwecke ein.</h:td><h:td>☐ nein\n<h:br />☐ ja</h:td></h:tr></h:tbody></h:table></h:div>',
  },
  url: 'http://thieme-compliance.de/fhir/Questionnaire/E-EDP2_0010006558',
  identifier: [
    {
      use: 'official',
      system: 'http://thieme-compliance.de/fhir/identifier/EcpBogencode',
      value: 'E-EDP2_0010006558',
    },
    {
      use: 'usual',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP 2_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP02_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP2_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/SapArtikelnummer',
      value: 'DECUSTOMER',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/Uuid',
      value: '00000000-0000-0000-0000-000000000000',
    },
  ],
  version: '05/2024',
  name: 'EDP 2_0010006558',
  title: 'EDP 2_0010006558 – Digitalisierung Kommunikation',
  status: 'active',
  experimental: true,
  subjectType: ['Patient'],
  date: '2021-08-02T16:32:49.175+02:00',
  publisher: 'Thieme Compliance GmbH, Am Weichselgarten 30a, 91058 Erlangen',
  contact: [
    {
      name: 'Homepage',
      telecom: [
        {
          system: 'url',
          value: 'www.thieme-compliance.de',
        },
      ],
    },
    {
      name: 'E-Mail',
      telecom: [
        {
          system: 'email',
          value: 'service@thieme-compliance.de',
        },
      ],
    },
    {
      name: 'Telefon',
      telecom: [
        {
          system: 'phone',
          value: '+49 (0)9131 93406-40',
        },
      ],
    },
    {
      name: 'Fax',
      telecom: [
        {
          system: 'fax',
          value: '+49 (0)9131 93406-70',
        },
      ],
    },
  ],
  description: 'Einwilligung zur Digitalisierung Ihrer Telefonnummer und E-Mail-Adresse (EDP 2_0010006558)',
  jurisdiction: [
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'AT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'A',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'CH',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'CH',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'DE',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'D',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'IT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'I',
        },
      ],
    },
  ],
  copyright: '© 2024 Thieme Compliance GmbH',
  approvalDate: '2024-05',
  lastReviewDate: '2024-08',
  item: [
    {
      linkId: 'E-EDP2_0010006558_05-2024_Frage_0',
      text: 'Einwilligung zur Digitalisierung Ihrer Telefonnummer und E-Mail-Adresse',
      type: 'group',
      required: false,
      item: [
        {
          extension: [
            {
              url: 'http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaire-collapsible',
              valueCode: 'default-closed',
            },
          ],
          linkId: 'Einwilligung_Digitalisierung_Telefonnummer_und_E-Mail_Details',
          text: 'Im Rahmen unserer Patientenverwaltung nutzen wir digitale Kommunikationskanäle, um Sie über wichtige Informationen zu Ihrer Gesundheit und geplanten Terminen zu informieren. Hierzu benötigen wir Ihre Einwilligung zur Digitalisierung Ihrer Telefonnummer und E-Mail-Adresse. Mit der digitalen Erfassung Ihrer Kontaktdaten ermöglichen Sie uns, Sie effektiver und zeitnah zu erreichen und somit Ihre Behandlung und Betreuung zu verbessern.',
          type: 'display',
        },
        {
          linkId: 'Einwilligung_Digitalisierung_Telefonnummer_und_E-Mail',
          text: 'Ich willige in die Verwendung meiner Telefonnummer und E-Mail-Adresse für digitale Kommunikationszwecke ein.',
          type: 'boolean',
          required: false,
        },
      ],
    },
  ],
}
