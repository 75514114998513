<div class="questionnaire-item-type_datetime">
  <mat-form-field>
    <mat-label>Choose a date</mat-label>
    <input matInput
           [id]="questionnaireItem.linkId+'_date'"
           [matDatepicker]="picker"
           [formControl]="formControl"
           (dateChange)="questionnaireItemChange.emit(formControl.getRawValue()?.toString())">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Pick a time</mat-label>
    <input matInput
           [id]="questionnaireItem.linkId+'_time'"
           [matTimepicker]="timepicker"
           [formControl]="formControl"
           (valueChange)="questionnaireItemChange.emit(formControl.getRawValue()?.toString())">
    <mat-timepicker-toggle matIconSuffix [for]="timepicker" />
    <mat-timepicker #timepicker />
  </mat-form-field>
</div>
