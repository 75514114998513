import { createFeatureSelector, createSelector } from '@ngrx/store'
import { BUILDER_FEATURE_KEY, BuilderState, builderAdapter } from './builder.reducer'

function getAllItems(obj: any, arr: Array<any> = []) {
  if (Array.isArray(obj)) {
    obj.forEach((el) => {
      return getAllItems(el, arr)
    })
  } else if (obj.items) {
    return getAllItems(obj.items, arr)
  } else {
    arr.push(obj)
  }
  return arr
}

// Lookup the 'Builder' feature state managed by NgRx
export const selectBuilderState = createFeatureSelector<BuilderState>(BUILDER_FEATURE_KEY)

const { selectAll, selectEntities } = builderAdapter.getSelectors()

export const selectBuilderLoaded = createSelector(selectBuilderState, (state: BuilderState) => state.loaded)

export const selectBuilderError = createSelector(selectBuilderState, (state: BuilderState) => state.error)

export const selectAllBuilder = createSelector(selectBuilderState, (state: BuilderState) => selectAll(state))

export const selectBuilderEntities = createSelector(selectBuilderState, (state: BuilderState) => selectEntities(state))

export const questions = createSelector(selectBuilderState, (state: BuilderState) => state.questions)

export const editQuestion = createSelector(selectBuilderState, (state: BuilderState) => state.editQuestion)

export const selectEnableWhenQuestions = createSelector(selectBuilderState, (state: BuilderState) =>
  getAllItems(state.questions),
)

export const properties = createSelector(selectBuilderState, (state: BuilderState) => ({
  name: state.name,
  title: state.title,
  id: state.id,
}))
