export const wahlleistungen = {
  resourceType: 'Questionnaire',
  id: 'E-EDP9-0010006558',
  meta: {
    profile: ['http://thieme-compliance.de/fhir/StructureDefinition/Questionnaire'],
  },
  language: 'de-DE',
  text: {
    status: 'generated',
    div: '<h:div xml:lang="de-DE" xmlns:h="http://www.w3.org/1999/xhtml"><h:h1>EDP 9_0010006558 – Wahlleistungsvereinbarung</h:h1><h:table><h:thead><h:tr><h:th>Nr.</h:th><h:th>Frage</h:th><h:th>Antwort</h:th></h:tr></h:thead><h:tbody><h:tr><h:td><h:b /></h:td><h:td><h:b>Wichtige Fragen</h:b></h:td><h:td /></h:tr><h:tr><h:td></h:td><h:td>Ich möchte für mich/​den oben genannten Patienten folgende angekreuzte gesondert berechenbaren Wahlleistungen in Anspruch nehmen:</h:td><h:td>☐ 1. ärztliche Wahlleistungen Die ärztlichen Leistungen aller an der Behandlung beteiligten Ärzte des Krankenhauses, soweit diese zur gesonderten Berechnung ihrer Leistungen im Rahmen der vollstationären und teilstationären sowie einer vor- und nachstationären Behandlung (§ 115a SGB V) berechtigt sind, einschließlich der von diesen Ärzten veranlaßten Leistungen von Ärzten und ärztlich geleiteten Einrichtungen außerhalb des Krankenhauses (§ 17 Abs. 3 S. 1 KHEntgG). Dies gilt auch, soweit die wahlärztlichen Leistungen vom Krankenhaus berechnet werden; die Liquidation erfolgt nach der GOÄ in der jeweils gültigen Fassung.\n<h:br />☐ 2. Unterbringung in einem 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag\n<h:br />☐ 3. Unterbringung in einem 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag\n<h:br />☐ 4. Aufnahme einer Begleitperson 28,00 EUR Zuschlag pro Berechnungstag (Regelstation)\n<h:br />☐ 5. Aufnahme einer Begleitperson 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag (Komfortstation)\n<h:br />☐ 6. Aufnahme einer Begleitperson 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag (Komfortstation)</h:td></h:tr></h:tbody></h:table></h:div>',
  },
  url: 'http://thieme-compliance.de/fhir/Questionnaire/E-EDP9_0010006558',
  identifier: [
    {
      use: 'official',
      system: 'http://thieme-compliance.de/fhir/identifier/EcpBogencode',
      value: 'E-EDP9_0010006558',
    },
    {
      use: 'usual',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP 9_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP09_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP9_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/SapArtikelnummer',
      value: 'DECUSTOMER',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/Uuid',
      value: '00000000-0000-0000-0000-000000000000',
    },
  ],
  version: '05/2024',
  name: 'EDP 9_0010006558',
  title: 'EDP 9_0010006558 – Wahlleistungsvereinbarung',
  status: 'active',
  experimental: true,
  subjectType: ['Patient'],
  date: '2021-08-02T16:32:49.175+02:00',
  publisher: 'Thieme Compliance GmbH, Am Weichselgarten 30a, 91058 Erlangen',
  contact: [
    {
      name: 'Homepage',
      telecom: [
        {
          system: 'url',
          value: 'www.thieme-compliance.de',
        },
      ],
    },
    {
      name: 'E-Mail',
      telecom: [
        {
          system: 'email',
          value: 'service@thieme-compliance.de',
        },
      ],
    },
    {
      name: 'Telefon',
      telecom: [
        {
          system: 'phone',
          value: '+49 (0)9131 93406-40',
        },
      ],
    },
    {
      name: 'Fax',
      telecom: [
        {
          system: 'fax',
          value: '+49 (0)9131 93406-70',
        },
      ],
    },
  ],
  description: 'Wahlleistungsvereinbarung (EDP 9_0010006558)',
  jurisdiction: [
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'AT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'A',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'CH',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'CH',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'DE',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'D',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'IT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'I',
        },
      ],
    },
  ],
  copyright: '© 2024 Thieme Compliance GmbH',
  approvalDate: '2024-05',
  lastReviewDate: '2024-08',
  item: [
    {
      linkId: 'E-EDP9_0010006558_05-2024_a1',
      text: 'zwischen der Klinikum Frankfurt (Oder) GmbH, Müllroser Chaussee 7, 15236 Frankfurt (Oder) als Träger des Krankenhauses und',
      type: 'display',
    },
    {
      linkId: 'E-EDP9_0010006558_05-2024_a2',
      text: 'der oben genannten Patientin/dem oben genannten Patienten',
      type: 'display',
    },
    {
      linkId: 'E-EDP9_0010006558_05-2024_a3',
      text: 'über die Gewährung der nachstehenden – neben den allgemeinen Krankenhausleistungen gesondert berechenbaren Wahlleistungen (gemäß § 17 KHEntgG)',
      type: 'display',
    },
    {
      linkId: 'E-EDP9_0010006558_05-2024_a4',
      text: 'Zu den in den Allgemeinen Vertragsbedingungen (AVB) des Klinikums in der jeweils gültigen Fassung und im Krankenhausentgelttarif genannten Bedingungen.',
      type: 'display',
    },
    {
      linkId: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen',
      text: 'Ich möchte für mich/​den oben genannten Patienten folgende angekreuzte gesondert berechenbaren Wahlleistungen in Anspruch nehmen:',
      type: 'choice',
      required: false,
      repeats: true,
      answerOption: [
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__aerztliche_Wahlleistungen',
          valueCoding: {
            display:
              '1. ärztliche Wahlleistungen Die ärztlichen Leistungen aller an der Behandlung beteiligten Ärzte des Krankenhauses, soweit diese zur gesonderten Berechnung ihrer Leistungen im Rahmen der vollstationären und teilstationären sowie einer vor- und nachstationären Behandlung (§ 115a SGB V) berechtigt sind, einschließlich der von diesen Ärzten veranlaßten Leistungen von Ärzten und ärztlich geleiteten Einrichtungen außerhalb des Krankenhauses (§ 17 Abs. 3 S. 1 KHEntgG). Dies gilt auch, soweit die wahlärztlichen Leistungen vom Krankenhaus berechnet werden; die Liquidation erfolgt nach der GOÄ in der jeweils gültigen Fassung.',
          },
        },
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Unterbringung_1-Bett-Zimmer',
          valueCoding: {
            display: '2. Unterbringung in einem 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag',
          },
        },
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Unterbringung_2-Bett-Zimmer',
          valueCoding: {
            display: '3. Unterbringung in einem 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag',
          },
        },
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_Regelstation',
          valueCoding: {
            display: '4. Aufnahme einer Begleitperson 28,00 EUR Zuschlag pro Berechnungstag (Regelstation)',
          },
        },
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_1-Bett-Zimmer_Komfortstation',
          valueCoding: {
            display:
              '5. Aufnahme einer Begleitperson 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag (Komfortstation)',
          },
        },
        {
          id: 'berechenbare_Wahlleistungen_in_Anspruch_nehmen__Aufnahme_Begleitperson_2-Bett-Zimmer_Komfortstation',
          valueCoding: {
            display:
              '6. Aufnahme einer Begleitperson 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag (Komfortstation)',
          },
        },
      ],
    },
    {
      linkId: 'E-EDP9_0010006558_05-2024_a16',
      text: 'Sollte die Unterbringung aufgrund der Bettenkapazität nicht in der von mir beantragten Kategorie erfolgen können, stimme ich, ohne dass es einer weiteren Erklärung bedarf, einer Unterbringung in der nächstniedrigeren Kategorie zu. In diesem Fall erfolgt selbstverständlich keine Berechnung des Einzelzimmerzuschlages.',
      type: 'display',
    },
  ],
}
