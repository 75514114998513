import { type Routes } from '@angular/router'
import { ProcessAdmissionComponent } from './process/process-admission.component'
import { CreateAdmissionComponent } from './create/create-admission.component'
import { provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import * as fromAdmission from './+state/admissions.reducer'
import { AdmissionsEffects } from './+state/admissions.effects'
import { ListAdmissionsComponent } from './list/list-admissions.component'
import { AuthGuard } from '../../-shared/guards/auth.guard'
import { AdmissionsFacade } from './+state/admissions.facade'
import { FhirQuestionnaireItemTypeShowcaseComponent } from './fhir-questionnaire-item-type-showcase/fhir-questionnaire-item-type-showcase.component'
import { NewAdmissionsComponent } from './new/new-admissions.component'
import { UnknownAdmissionComponent } from './unknown/unknown-admission.component'
/*
  IMPORTANT: App base is set in appConfig via APP_BASE_HREF and it should be /realms/{realmName}
  All routes are sub-pages of that base url, i.e. /home is basically realm/realm-name/home
*/
export const AUTHORIZATION_READ_ADMISSIONS_ABBREVIATION = 'r-admission'
export const AUTHORIZATION_WRITE_ADMISSIONS_ABBREVIATION = 'w-admission'
export const admissionsRoutes: Routes = [
  {
    path: 'admissions',
    children: [
      {
        path: 'list',
        component: ListAdmissionsComponent,
        canActivate: [AuthGuard],
        data: { authorization: [AUTHORIZATION_READ_ADMISSIONS_ABBREVIATION] },
        title: 'CaaS - Admissions list',
      },
      {
        path: 'new',
        component: NewAdmissionsComponent,
        canActivate: [AuthGuard],
        data: {
          authorization: [AUTHORIZATION_READ_ADMISSIONS_ABBREVIATION, AUTHORIZATION_WRITE_ADMISSIONS_ABBREVIATION],
        },
        title: 'CaaS - New Admission',
      },
      {
        path: 'unknown',
        component: UnknownAdmissionComponent,
        data: { authorization: [AUTHORIZATION_READ_ADMISSIONS_ABBREVIATION] },
        title: 'CaaS - Admission Error',
      },
      {
        path: 'process/:id',
        component: ProcessAdmissionComponent,
        canActivate: [AuthGuard],
        data: { authorization: [AUTHORIZATION_READ_ADMISSIONS_ABBREVIATION] },
        title: 'CaaS - Process admission',
      },
      {
        path: 'showcase',
        component: FhirQuestionnaireItemTypeShowcaseComponent,
        canActivate: [AuthGuard],
        // data: { authorization: AUTHORIZATION_ADMISSIONS_ABBREVIATION },
        title: 'CaaS - Fhir Questionnaire Item Type Showcase',
      },
      {
        path: 'create',
        children: [
          {
            path: '**',
            component: CreateAdmissionComponent,
          },
        ],
      },
    ],
    providers: [
      AdmissionsFacade,
      provideState(fromAdmission.ADMISSIONS_FEATURE_KEY, fromAdmission.admissionsReducer),
      provideEffects(AdmissionsEffects),
    ],
  },
]
