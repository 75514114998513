import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import * as UserManagementActions from './user-management.actions'
import { User } from '../../../generated'
import { Sort } from '@angular/material/sort'
import { HttpErrorResponse } from '@angular/common/http'

export const USER_MANAGEMENT_FEATURE_KEY = 'users'

export interface UserManagementState extends EntityState<User> {
  selectedId?: string | number // which UserManagement record has been selected
  loaded: boolean // has the UserManagement list been loaded
  error?: string | null | HttpErrorResponse // last known error (if any)
  updateError?: null | HttpErrorResponse // last known error (if any)
  sort: Sort | undefined
  filter: string
  updating?: boolean
}

export interface UserManagementPartialState {
  readonly [USER_MANAGEMENT_FEATURE_KEY]: UserManagementState
}

export const userManagementAdapter: EntityAdapter<User> = createEntityAdapter<User>()

export const initialUserManagementState: UserManagementState = userManagementAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  sort: undefined,
  filter: '',
  updating: false,
})

const reducer = createReducer(
  initialUserManagementState,
  on(UserManagementActions.loadUserManagementSuccess, (state, { users }) =>
    userManagementAdapter.setAll(users, { ...state, loaded: true }),
  ),
  on(UserManagementActions.loadUserManagementFailure, (state, { error }) => ({ ...state, error })),
  on(UserManagementActions.sortUsers, (state, { sort }) => ({ ...state, sort })),
  on(UserManagementActions.filterUsers, (state, { filter }) => ({ ...state, filter })),
  on(UserManagementActions.createUser, (state, { user }) => ({ ...state, updating: true, updateError: null })),
  on(UserManagementActions.createUserSuccess, (state, { user }) =>
    userManagementAdapter.addOne(user, {
      ...state,
      updating: false,
    }),
  ),
  on(UserManagementActions.createUserFailure, (state, { error }) => ({
    ...state,
    updating: false,
    updateError: error,
  })),
  on(UserManagementActions.updateUser, (state, { user }) => ({ ...state, updating: true, updateError: null })),
  on(UserManagementActions.updateUserSuccess, (state, { user }) =>
    userManagementAdapter.setOne(user, { ...state, updating: false }),
  ),
  on(UserManagementActions.updateUserFailure, (state, { error }) => ({
    ...state,
    updating: false,
    updateError: error,
  })),
  on(UserManagementActions.deleteUserSuccess, (state, { user }) => userManagementAdapter.removeOne(user.id, state)),
  on(UserManagementActions.patchEnabledStatusSuccess, (state, { user }) =>
    userManagementAdapter.updateOne(user, state),
  ),
)

export function userManagementReducer(state: UserManagementState | undefined, action: Action) {
  return reducer(state, action)
}
