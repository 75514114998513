import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionComponent } from '../../../questionnaire-builder/question/question.component'
import { Questionnaire, QuestionnaireItem } from '../../../questionnaire-builder/models/fhir-interfaces'
import { EnableWhenService } from '../../../-shared/services/enable-when.service'
import { animate, style, transition, trigger } from '@angular/animations'

export const slideDownUpAnimation = trigger('slideDownUp', [
  transition(':enter', [style({ height: 0, opacity: 0 }), animate(200)]),
  transition(':leave', [animate(200, style({ height: 0, opacity: 0 }))]),
])

@Component({
  selector: 'app-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent, CommonModule],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
  animations: [slideDownUpAnimation],
})
export class QuestionnaireRendererComponent implements OnInit {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''
  @Input() questionnaire: Questionnaire = { language: '', name: '', resourceType: '', status: '', item: [], title: '' }
  private enableWhenService = inject(EnableWhenService)
  private visibilities: { [key: string]: boolean } = {}

  ngOnInit(): void {
    this.enableWhenService.initialize(this.questionnaire.item)

    this.enableWhenService.visibilityChanges$.subscribe((visibility) => {
      this.visibilities = visibility
    })
  }

  isVisible(linkId: string, item: QuestionnaireItem): boolean {
    if (item.enableWhen?.length) {
      return this.visibilities[linkId]
    }
    return true
  }

  // isQuestionEnabled = computed(() => {
  //   if (!question.enableWhen || !question.enableWhen.length) {
  //     return true
  //   }
  //   return false

  //   // if (showCount()) {
  //   //   return `The count is ${count()}.`;
  //   // } else {
  //   //   return 'Nothing to see here!';
  //   // }
  // })

  // isQuestionEnabled(question: QuestionnaireItem) {
  //   // console.log('isQuestionEnabled')
  //   if (!question.enableWhen || !question.enableWhen.length) {
  //     return true
  //   }
  //   return false
  // }
}
