@if (title) {
  <h1 class="questionnaire-title heading-s">{{ title }}</h1>
}
@for (item of questions; track item.linkId) {
  <div
    [ngClass]="{ 'group-container': item.type === 'group', 'canvas-item': item.type !== 'group' }"
    [id]="item.linkId"
  >
    @if (item.type === 'group') {
      <div class="group" [class]="'layout-' + item.layout" [id]="item.linkId">
        <app-questionnaire-renderer
          [questions]="item.items || []"
          [questionnaire]="questionnaire"
        ></app-questionnaire-renderer>
      </div>
    }
    @if (isVisible(item.linkId, item)) {
      <app-question @slideDownUp [question]="item"></app-question>
    }
  </div>
}
