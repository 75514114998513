import {
  APP_INITIALIZER,
  type ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
} from '@angular/core'
import { provideRouter, Router, withComponentInputBinding } from '@angular/router'
import { APP_BASE_HREF } from '@angular/common'
import { routes } from './app.routes'
import { HttpClient, provideHttpClient } from '@angular/common/http'
import { TranslateLoader, TranslateModule, TranslationObject } from '@ngx-translate/core'
import { type Observable } from 'rxjs'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { getRealmNameFromUrl } from './-shared/utils/utils'
import { provideState, provideStore } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import * as fromApp from './+state/app.reducer'
import { AppEffects } from './+state/app.effects'
import { AppFacade } from './+state/app.facade'
import { provideRouterStore } from '@ngrx/router-store'
import { ApiModule, Configuration, ConfigurationParameters } from './generated'
import { env } from '../environments/environment'
import { AuthFacade } from './components/auth/+state/auth.facade'
import { AuthEffects } from './components/auth/+state/auth.effects'
import * as fromAuth from './components/auth/+state/auth.reducer'
import * as Sentry from '@sentry/angular'
import { metaReducers } from './+state/app.meta-reducer'

export class TranslateHttpLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    public prefix = '/assets/i18n/',
    public suffix = '.json',
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<TranslationObject> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
  }
}

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient)
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: env.authUrl,
  }
  return new Configuration(params)
}

Sentry.init({
  dsn: 'https://f7f862d7a7b6e5414cc96d821fdf799d@sentry.thieme.de/13',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: env.production ? 'production' : 'development',
})

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: APP_BASE_HREF, useValue: `/realms/${getRealmNameFromUrl(window.location.href)}` },
    AppFacade,
    provideEffects(AppEffects),
    provideState(fromApp.APP_FEATURE_KEY, fromApp.appReducer),
    provideEffects(),
    AuthFacade,
    provideState(fromAuth.AUTH_FEATURE_KEY, fromAuth.authReducer),
    provideEffects(AuthEffects),
    provideStore(
      {},
      {
        runtimeChecks: {},
        metaReducers, // <--- Meta reducers here
      },
    ),
    provideRouterStore(),
    provideRouter(routes, withComponentInputBinding()),
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: window.localStorage?.getItem('lang') ?? 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimationsAsync(),
    importProvidersFrom([ApiModule.forRoot(apiConfigFactory)]),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // empty factory
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
}
