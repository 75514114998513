<cc-label [id]="groupId" [label]="label"></cc-label>
<main style="display: flex">
  <section class="toggle-buttons-wrapper grid-{{ buttonElements.length }}">
    @for (element of buttonElements; track $index + element.value; let i = $index) {
      <label #item>
        <span> {{ element.label | translate }}</span>
        <input
          [id]="uuid"
          [checked]="value === element.value"
          [disabled]="element.disabled"
          (click)="onSelectionChange($event)"
          type="radio"
          class="radio-button"
          name="{{ groupId }}"
          value="{{ element.value }}"
        />
      </label>
    }
  </section>
</main>
