<div class="fhir-renderer scroll-anchor" [id]="activeQuestionnaire?.id || ''">
  @if (activeQuestionnaire && activeQuestionnaire.item) {
    <h5 class="heading-m m-semibold">{{ activeQuestionnaire.title }}</h5>
    @for (activeQuestionnaireItem of activeQuestionnaire.item; track activeQuestionnaireItem.linkId; let i = $index) {
      @if (isVisible(activeQuestionnaireItem)) {
        <ng-template #itemRef>
          <fhir-questionnaire-item
            class="fhir-renderer_top-level_item-group"
            (emitQuestionnaireItemChange)="questionnaireItemChange()"
            [questionnaireItem]="activeQuestionnaireItem"
          />
        </ng-template>
        @if (activeQuestionnaireItem.type === 'group') {
          <section class="card">
            @if (activeQuestionnaireItem.text && activeQuestionnaireItem.type == 'group') {
              <h6 class="heading-s">{{ activeQuestionnaireItem.text }}</h6>
              <div style="margin: 0 6px">
                <cc-divider></cc-divider>
              </div>
            }
            <ng-template [ngTemplateOutlet]="itemRef"></ng-template>
          </section>
        } @else {
          <ng-template [ngTemplateOutlet]="itemRef"></ng-template>
        }
      }
    }
  }
</div>
