<aside class="sidebar card-plain">
  <ul class="sidebar-list">
    @if (admissionAvailable) {
      <li class="sidebar-list_item" data-testid="admissions-sidebar" [class.is-finished]="admissionAvailable">
        <p class="text-xs m-semibold text">
          <span class="icon">
            <app-icon-circle-check-filled></app-icon-circle-check-filled>
          </span>
          Aufnahmeformulare auswählen
        </p>
      </li>

      <li class="sidebar-list_item is-current has-scroll-indicator">
        <div class="text-xs m-semibold text">
          <span class="icon"> </span>{{ bogenpaket?.name }}
          <div class="progress-number text-xs">
            <span class="number-finished">{{ finishedFormIds.length }}</span>
            <span>/{{ bogenpaket?.forms?.length }}</span>
          </div>
        </div>
        <ul class="nested-list">
          @for (form of bogenpaket?.forms; track form.id) {
            <li
              class="text-xs"
              [class.is-current]="currentFormIds.includes(form.id)"
              [class.is-finished]="finishedFormIds.includes(form.id)"
            >
              <p class="text-xs m-semibold text">
                <a [href]="form.id" data-testid="navigate-link" (click)="handleNavigateToForm($event, form.id)">
                  {{ form.name }}</a
                >

                <span class="icon">
                  @if (finishedFormIds.includes(form.id)) {
                    <app-icon-circle-check-filled></app-icon-circle-check-filled>
                  } @else if (!currentFormIds.includes(form.id)) {
                    <!-- This is + icon -->
                    <app-icon-circle-plus-outline></app-icon-circle-plus-outline>
                  }
                </span>
              </p>
            </li>
          }
        </ul>
      </li>
      <li class="sidebar-list_item">
        <p class="text-xs m-semibold text"><span class="icon is-disabled"></span>Unterschrift</p>
      </li>
    } @else {
      <li class="sidebar-list_item is-current" data-testid="initial-sidebar" [class.is-finished]="admissionAvailable">
        <p class="text-xs m-semibold text"><span class="icon"> </span>Aufnahmeformulare auswählen</p>
      </li>

      <li class="sidebar-list_item">
        <p class="text-xs m-semibold text"><span class="icon is-disabled"></span> Aufnahme starten</p>
      </li>
    }
  </ul>
</aside>
