export const koraWahlleistungen = {
  resourceType: 'Questionnaire',
  id: 'E-EDP9-0010006558',
  meta: {
    profile: ['http://thieme-compliance.de/fhir/StructureDefinition/Questionnaire'],
  },
  language: 'de-DE',
  text: {
    status: 'generated',
    div: '<h:div xml:lang="de-DE" xmlns:h="http://www.w3.org/1999/xhtml"><h:h1>EDP 9_0010006558 – Wahlleistungsvereinbarung</h:h1><h:table><h:thead><h:tr><h:th>Nr.</h:th><h:th>Frage</h:th><h:th>Antwort</h:th></h:tr></h:thead><h:tbody><h:tr><h:td><h:b /></h:td><h:td><h:b>Wichtige Fragen</h:b></h:td><h:td /></h:tr><h:tr><h:td></h:td><h:td>Ich möchte für mich/​den oben genannten Patienten folgende angekreuzte gesondert berechenbaren Wahlleistungen in Anspruch nehmen:</h:td><h:td>☐ 1. ärztliche Wahlleistungen Die ärztlichen Leistungen aller an der Behandlung beteiligten Ärzte des Krankenhauses, soweit diese zur gesonderten Berechnung ihrer Leistungen im Rahmen der vollstationären und teilstationären sowie einer vor- und nachstationären Behandlung (§ 115a SGB V) berechtigt sind, einschließlich der von diesen Ärzten veranlaßten Leistungen von Ärzten und ärztlich geleiteten Einrichtungen außerhalb des Krankenhauses (§ 17 Abs. 3 S. 1 KHEntgG). Dies gilt auch, soweit die wahlärztlichen Leistungen vom Krankenhaus berechnet werden; die Liquidation erfolgt nach der GOÄ in der jeweils gültigen Fassung.\n<h:br />☐ 2. Unterbringung in einem 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag\n<h:br />☐ 3. Unterbringung in einem 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag\n<h:br />☐ 4. Aufnahme einer Begleitperson 28,00 EUR Zuschlag pro Berechnungstag (Regelstation)\n<h:br />☐ 5. Aufnahme einer Begleitperson 1-Bett-Zimmer 175,00 EUR Zuschlag pro Berechnungstag (Komfortstation)\n<h:br />☐ 6. Aufnahme einer Begleitperson 2-Bett-Zimmer 90,00 EUR Zuschlag pro Berechnungstag (Komfortstation)</h:td></h:tr></h:tbody></h:table></h:div>',
  },
  url: 'http://thieme-compliance.de/fhir/Questionnaire/E-EDP9_0010006558',
  identifier: [
    {
      use: 'official',
      system: 'http://thieme-compliance.de/fhir/identifier/EcpBogencode',
      value: 'E-EDP9_0010006558',
    },
    {
      use: 'usual',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP 9_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP09_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/TcgBogencode',
      value: 'EDP9_0010006558',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/SapArtikelnummer',
      value: 'DECUSTOMER',
    },
    {
      use: 'secondary',
      system: 'http://thieme-compliance.de/fhir/identifier/Uuid',
      value: '00000000-0000-0000-0000-000000000000',
    },
  ],
  version: '05/2024',
  name: 'EDP 9_0010006558',
  title: 'Wahlleistungsvereinbarung',
  status: 'active',
  experimental: true,
  subjectType: ['Patient'],
  date: '2021-08-02T16:32:49.175+02:00',
  publisher: 'Thieme Compliance GmbH, Am Weichselgarten 30a, 91058 Erlangen',
  contact: [
    {
      name: 'Homepage',
      telecom: [
        {
          system: 'url',
          value: 'www.thieme-compliance.de',
        },
      ],
    },
    {
      name: 'E-Mail',
      telecom: [
        {
          system: 'email',
          value: 'service@thieme-compliance.de',
        },
      ],
    },
    {
      name: 'Telefon',
      telecom: [
        {
          system: 'phone',
          value: '+49 (0)9131 93406-40',
        },
      ],
    },
    {
      name: 'Fax',
      telecom: [
        {
          system: 'fax',
          value: '+49 (0)9131 93406-70',
        },
      ],
    },
  ],
  description: 'Wahlleistungsvereinbarung (EDP 9_0010006558)',
  jurisdiction: [
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'AT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'A',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'CH',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'CH',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'DE',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'D',
        },
      ],
    },
    {
      coding: [
        {
          system: 'urn:iso:std:iso:3166',
          code: 'IT',
        },
        {
          system: 'http://fhir.de/CodeSystem/deuev/anlage-8-laenderkennzeichen',
          code: 'I',
        },
      ],
    },
  ],
  copyright: '© 2024 Thieme Compliance GmbH',
  approvalDate: '2024-05',
  lastReviewDate: '2024-08',
  item: [
    {
      linkId: 'group1',
      type: 'group',
      text: '',
      item: [
        {
          linkId: '1',
          type: 'display',
          text: 'Ich möchte die ärztlichen Wahlleistungen in Anspruch nehmen.',
        },
        {
          linkId: '2',
          type: 'display',
          text: '⌄ Volltext anzeigen (extension not available)',
        },

        {
          linkId: 'wahlleistungen ja nein',
          type: 'boolean',
          text: '',
        },
      ],
    },
    {
      linkId: 'group2',
      type: 'group',
      text: '',
      item: [
        {
          linkId: '3',
          type: 'display',
          text: 'Ich wünsche die Unterbringung in einem dieser Zimmer.',
        },
        {
          linkId: '4',
          type: 'display',
          text: '⌄ Die Kosten pro Berechnungstag liegen bei 175,00€ Zuschlag (1-Bett-Zimmer), oder 90,00€ Zuschlag (2-Bett-Zimmer).',
        },

        {
          linkId: '5',
          text: '',
          type: 'choice',
          required: true,
          answerOption: [
            {
              id: '1-bett-zimmer',
              valueCoding: {
                display: '1-Bett-Zimmer',
                system: '1-bett-zimmer',
              },
            },
            {
              id: '2-bett-zimmer',
              valueCoding: {
                display: '2-Bett-Zimmer',
                system: '2-bett-zimmer',
              },
            },
          ],
        },
      ],
    },
  ],
}
