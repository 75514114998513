<div class="{{ userEditable ? 'question user-input' : 'question editor-input' }}">
  <div>
    @switch (question.type) {
      @case ('number') {
        <cc-input-field
          [label]="question.text || ''"
          (inputValueChange)="updateAnswer(question.linkId, $event)"
          class="el-input"
          [type]="'number'"
        ></cc-input-field>
      }
      @case ('input') {
        <cc-input-field
          (inputValueChange)="updateAnswer(question.linkId, $event)"
          class="el-input"
          [label]="question.text || ''"
        ></cc-input-field>
      }
      @case ('string') {
        <p class="el-string">{{ question.text }}</p>
      }
      @case ('boolean') {
        <cc-toggle-buttons
          (valueChange)="updateAnswer(question.linkId, $event)"
          class="fhir-renderer_item"
          [label]="question.text || ''"
          [buttonElements]="fieldOptions"
        />
      }
      @case ('date') {
        <p>{{ question.text }}</p>
        <input type="date" disabled />
      }
      @case ('choice') {
        <cc-select-field
          id="language-select"
          style="width: 100%"
          [label]="question.text || ''"
          [options]="fieldOptions"
          [selected]="selectedOption"
          (selectedChange)="updateAnswer(question.linkId, $event)"
        >
        </cc-select-field>
      }
      @case ('header') {
        <h4 class="el-headline heading-xs m-semibold">{{ question.text }}</h4>
      }
      @case ('variable') {
        <p class="variable">{{ question.text }}</p>
      }
      @case ('signature') {
        <article class="el-signature flex-column">
          <h3 class="text-m">{{ question.text }}</h3>

          <signature-pad #sigPad class="pen-area" [options]="signaturePadOptions"></signature-pad>

          <div class="flex">
            <button class="btn-secondary x-small" (click)="sigPad.clear()">Clear</button>
          </div>
        </article>
      }
    }

    @if (question.required) {
      <span class="text-xs">* Required</span>
    }
  </div>
</div>
