@if (questionnaireItem) {
  <div class="fhir-renderer_item">
    @switch (questionnaireItem.type) {
      @case ('group') {
        @for (questionnaireItem of questionnaireItem.item; track questionnaireItem.linkId; let i = $index) {
          @if (questionnaireItem.item && questionnaireItem.text) {
            <h6 class="heading-xs m-semibold">{{ questionnaireItem.text }}</h6>
          }
          <fhir-questionnaire-item
            [class]="'fhir-renderer_item-group-' + i"
            [class.fhir-renderer_item-group]="questionnaireItem.item"
            [class.fhir-renderer_itemless-group]="!questionnaireItem.item"
            [questionnaireItem]="questionnaireItem"
            (emitQuestionnaireItemChange)="emitQuestionnaireItemChange.emit()"
          />
        }
      }
      @case ('string') {
        <fhir-questionnaire-item-type-string
          [questionnaireItem]="questionnaireItem"
          (questionnaireItemChange)="setAnswerAndEmitChange($event, questionnaireItem.linkId)"
        />
      }
      @case ('question') {
        <fhir-questionnaire-item-type-string
          [questionnaireItem]="questionnaireItem"
          (questionnaireItemChange)="setAnswerAndEmitChange($event, questionnaireItem.linkId)"
        />
      }
      @case ('text') {
        <label class="label-text text-xs m-semibold">{{ questionnaireItem.text }}</label>
        <mat-form-field>
          <textarea
            matInput
            [id]="questionnaireItem.linkId"
            [placeholder]="questionnaireItem.text"
            [formControl]="formControl"
            (change)="setAnswerAndEmitChange(formControl.getRawValue(), questionnaireItem.linkId)"
          ></textarea>
        </mat-form-field>
      }
      @case ('boolean') {
        <label class="label-text text-xs m-semibold">{{ questionnaireItem.text }}</label>
        <mat-button-toggle-group
          [id]="questionnaireItem.linkId"
          [name]="questionnaireItem.text"
          aria-label="Font Style"
          [vertical]="isVertical(questionnaireItem)"
          class="fhir-button-toggle"
          (valueChange)="setAnswerAndEmitChange($event, questionnaireItem.linkId)"
        >
          @for (b of createButtonElementsForBoolean; track b.value) {
            <mat-button-toggle [value]="b.value | translate">{{ b.label | translate }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
      @case ('choice') {
        <label class="label-text text-xs m-semibold">{{ questionnaireItem.text }}</label>
        <mat-button-toggle-group
          [id]="questionnaireItem.linkId"
          [name]="questionnaireItem.text"
          aria-label="Font Style"
          [vertical]="isVertical(questionnaireItem)"
          class="fhir-button-toggle"
          (valueChange)="setAnswerAndEmitChange($event, questionnaireItem.linkId)"
          [multiple]="questionnaireItem.repeats"
        >
          @for (ao of questionnaireItem.answerOption; track ao.valueCoding) {
            <mat-button-toggle [value]="ao.valueCoding.code"
              >{{ ao.valueCoding.display | translate }}
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
      @case ('date') {
        <mat-form-field>
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [id]="questionnaireItem.linkId"
            [matDatepicker]="picker"
            [formControl]="formControl"
            (dateChange)="setAnswerAndEmitChange(formControl.getRawValue(), questionnaireItem.linkId)"
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      }
      @case ('dateTime') {
        <fhir-questionnaire-item-type-datetime
          [questionnaireItem]="questionnaireItem"
          (questionnaireItemChange)="setAnswerAndEmitChange($event, questionnaireItem.linkId)"
        />
      }
      @case ('time') {
        <mat-form-field>
          <mat-label>Pick a time</mat-label>
          <input
            matInput
            [id]="questionnaireItem.linkId"
            [matTimepicker]="timepicker"
            [formControl]="formControl"
            (change)="setAnswerAndEmitChange(formControl.getRawValue(), questionnaireItem.linkId)"
          />
          <mat-timepicker-toggle matIconSuffix [for]="timepicker" />
          <mat-timepicker #timepicker />
        </mat-form-field>
      }
      @case ('display') {
        <p>
          {{ questionnaireItem.text }}
        </p>
      }
      @default {
        <p>
          {{ questionnaireItem.text }}
        </p>
      }
    }
  </div>
}
