import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActiveQuestionnaireItem } from '@client-workspace/fhir-engine'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatTimepickerModule } from '@angular/material/timepicker'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'fhir-questionnaire-item-type-datetime',
  standalone: true,
  imports: [
    CommonModule,
    MatTimepickerModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './questionnaire-item-type-datetime.component.html',
  styleUrl: './questionnaire-item-type-datetime.component.scss',
  providers: [MatDatepickerModule],
})
export class QuestionnaireItemTypeDatetimeComponent implements OnInit {
  @Input() questionnaireItem!: ActiveQuestionnaireItem
  @Output() questionnaireItemChange = new EventEmitter<string | null>()
  formControl: FormControl<string | Date | null> = new FormControl(new Date())

  ngOnInit() {
    const value = this.questionnaireItem.answer?.getAnswer() as string
    value && this.formControl.setValue(value)
  }
}
