import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IconCircleQuestionFilledComponent } from '@client-workspace/icons/circle_question_filled.component'
import { AutoFocusDirective } from '../directives/auto-focus.directive'
import { v4 as uuidv4 } from 'uuid'

type Status = 'success' | 'error' | undefined
@Component({
  selector: 'cc-input-field',
  standalone: true,
  imports: [FormsModule, IconCircleQuestionFilledComponent, AutoFocusDirective],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
})
export class InputFieldComponent {
  @Input() value = ''
  @Input() label = ''
  @Input() placeholder = ''
  @Input() subtext = ''
  @Input() deletable = false
  @Input() status: Status
  @Input() disabled = false
  @Input() type = 'text'
  @Input() hint = undefined
  @Input() autoFocus = false
  @Input() name = ''
  @Input() autocomplete = 'off'
  @Output() valueChange = new EventEmitter<string>()
  @Output() inputValueChange = new EventEmitter<string>()

  @HostBinding('class') get classAttribute(): string {
    return this.status ? `status-${this.status}` : ''
  }

  uuid = uuidv4()

  handleClearClick() {
    this.value = ''
  }

  valueChanged(value: string) {
    this.value = value
    this.valueChange.emit(this.value)
  }
}
