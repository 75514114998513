import { Component, inject, Input, OnChanges } from '@angular/core'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import {
  ButtonElementOption,
  InputFieldComponent,
  SelectFieldComponent,
  ToggleButtonsComponent,
  Value,
} from '@client-workspace/custom-components'
import { AngularSignaturePadModule } from '@mineminemine/angular-signature-pad'
import { EnableWhenService } from '@client-workspace/fhir-engine'

@Component({
  standalone: true,
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  imports: [InputFieldComponent, ToggleButtonsComponent, SelectFieldComponent, AngularSignaturePadModule],
})
export class QuestionComponent implements OnChanges {
  @Input() userEditable = false
  @Input() question: QuestionnaireItem = { linkId: '', text: '', type: '' }
  fieldOptions: ButtonElementOption[] = []
  selectedOption: Value = ''
  private enableWhenService = inject(EnableWhenService)

  signaturePadOptions = {
    minWidth: 1,
    canvasWidth: 600,
    canvasHeight: 200,
  }

  ngOnChanges() {
    if (!this.question.answerOptions) return
    this.fieldOptions = this.question.answerOptions
      ? (this.question.answerOptions?.map((answerOption) => ({
          label: answerOption.valueCoding?.display,
          value: answerOption.valueCoding?.display,
          checked: answerOption.initialSelected,
        })) as ButtonElementOption[])
      : ([] as ButtonElementOption[])
    this.selectedOption = this.fieldOptions.find((el) => el.checked)?.value || ''
  }

  updateAnswer(linkId: string, answer: any) {
    this.enableWhenService.updateAnswer(linkId, answer.toString())
  }
}
