import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconCircleCheckFilledComponent } from '@client-workspace/icons/circle_check_filled.component'
import { IconCirclePlusOutlineComponent } from '@client-workspace/icons/circle_plus_outline.component'
import { Bogenpaket } from '../new/new-admissions.component'

@Component({
  selector: 'app-navigation-panel',
  standalone: true,
  imports: [CommonModule, IconCirclePlusOutlineComponent, IconCircleCheckFilledComponent],
  templateUrl: './navigation-panel.component.html',
  styleUrl: './navigation-panel.component.scss',
})
export class NavigationPanelComponent {
  @Input() finishedFormIds: Array<string> = [] //'E-EDPXX-0000000000']
  @Input() currentFormIds: Array<string> = [] //'E-EDP9-0010006558']
  @Input() bogenpaket: Bogenpaket | undefined = undefined
  @Input() admissionAvailable = false
  @Output() navigateToForm: EventEmitter<string> = new EventEmitter<string>()

  handleNavigateToForm(event: MouseEvent, id: string) {
    event.preventDefault()
    event.stopPropagation()
    this.navigateToForm.emit(id)
  }
}
