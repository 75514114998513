import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionnaireItemComponent } from './questionnaire-item/questionnaire-item.component'
import { DividerComponent } from '@client-workspace/custom-components'
import { FhirEnableWhen, FhirQuestionnaire, FhirQuestionnaireResponse } from '../fhir-deserializer/types/fhir.type'
import { ActiveQuestionnaire, ActiveQuestionnaireItem } from '../fhir-deserializer/types/active-questionnaire.type'
import { group } from '@angular/animations'
import { EnableWhenService } from '@client-workspace/fhir-engine'

@Component({
  selector: 'fhir-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionnaireItemComponent, DividerComponent],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
})
export class QuestionnaireRendererComponent implements OnInit {
  @Input() questionnaire!: FhirQuestionnaire
  @Input() questionnaireResponse?: FhirQuestionnaireResponse | undefined

  @Output() questionnaireResponseChange = new EventEmitter<FhirQuestionnaireResponse>()
  @Output() questionnaireCompletenessChange = new EventEmitter<{ id: string; completed: boolean }>()

  activeQuestionnaire: ActiveQuestionnaire | undefined
  enableWhenService = inject(EnableWhenService)
  visibilities: { [key: string]: boolean } = {}

  ngOnInit(): void {
    if (this.questionnaire === undefined) return
    this.activeQuestionnaire = new ActiveQuestionnaire(this.questionnaire, this.questionnaireResponse)

    if (this.activeQuestionnaire.item) {
      this.enableWhenService.initialize(this.activeQuestionnaire.item)
      this.enableWhenService.visibilityChanges$.subscribe((visibility) => {
        this.visibilities = visibility
      })
    }

    this.questionnaireItemChange()
  }

  questionnaireItemChange() {
    const response = this.activeQuestionnaire?.extractResponse()
    this.questionnaireResponseChange.emit(response)

    const requiredItems = this.getAllItems(this.activeQuestionnaire!).filter((item) => item.required)
    const isCompleted = requiredItems.every((item) => {
      return !!item.answer?.getAnswer()
    })

    this.questionnaireCompletenessChange.emit({ id: this.activeQuestionnaire?.id || '', completed: isCompleted })
  }

  getAllItems(
    obj: Array<ActiveQuestionnaire | ActiveQuestionnaireItem> | ActiveQuestionnaire | ActiveQuestionnaireItem,
    arr: Array<ActiveQuestionnaireItem> = [],
  ): Array<ActiveQuestionnaireItem> {
    if (Array.isArray(obj)) {
      obj.forEach((el) => {
        return this.getAllItems(el, arr)
      })
    } else if (obj.item) {
      return this.getAllItems(obj.item, arr)
    } else {
      // @ts-expect-error
      arr.push(obj)
    }
    return arr
  }

  isVisible(item: ActiveQuestionnaireItem): boolean {
    if (item.enableWhen?.length) {
      return this.visibilities[item.linkId]
    }
    return true
  }

  protected readonly group = group
}
