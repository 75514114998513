<section class="questionnaire-panel">
  <div>
    <h4>Questionnaire name:</h4>
    <cc-input-field placeholder="Name" [(value)]="properties.name"></cc-input-field>
  </div>
  <div>
    <h4>Title:</h4>
    <cc-input-field placeholder="Title" [(value)]="properties.title"></cc-input-field>
  </div>

  <label class="preview-toggle toggle small">Vorschau<input type="checkbox" /> </label>
  <button class="btn-secondary small" type="button" (click)="saveProperties()">Speichern</button>
</section>
@if (selectedItem) {
  <div class="properties-panel">
    @switch (selectedItem.type) {
      @case ('number') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('header') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('input') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('boolean') {
        <section>
          <div>
            <h3 class="text-s mb-10">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>

          @for (option of selectedItem.answerOptions; track option.id) {
            <div>
              <input type="text" [(ngModel)]="option.valueCoding.display" />
            </div>
          }

          <button class="btn-primary x-small" type="button" (click)="addChoiceOrCheckbox()">Add Choice</button>
        </section>
      }
      @case ('choice') {
        <section>
          <div>
            <h3 class="text-s">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>

          @for (option of selectedItem.answerOptions; track option.id) {
            <div>
              <input
                type="radio"
                [name]="selectedItem.linkId"
                (click)="markPreselected(option.id)"
                [checked]="option.initialSelected"
              />
              <input type="text" [(ngModel)]="option.valueCoding.display" />
            </div>
          }

          <div>
            <button class="btn-primary x-small" type="button" (click)="addChoiceOrCheckbox()">Add Choice</button>
          </div>

          <label>
            <span class="text-s"> Required </span>
            <input type="checkbox" name="enabled-toggle" class="toggle small" [(ngModel)]="isRequired" />
          </label>
        </section>
      }

      @case ('string') {
        <section>
          <div>
            <h3 class="text-s">Text:</h3>
            <textarea rows="5" [(ngModel)]="newCustomText"></textarea>
          </div>
        </section>
      }
      @case ('signature') {
        <section>
          <div>
            <h3 class="text-s">Title:</h3>
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
          </div>
        </section>
      }
      @case ('group') {
        <section>
          <p>Layout</p>
          <div class="flex-column">
            @for (layout of ['100', '30-70', '70-30', '50-50']; track layout) {
              <label>
                <input
                  name="layout"
                  type="radio"
                  (click)="setGroupLayout(layout)"
                  [checked]="selectedItem.layout === layout"
                />
                <span [class]="'layout-' + layout"> {{ layout }}</span>
              </label>
            }
          </div>
        </section>
      }
    }

    @if (['input', 'date', 'signature'].includes(selectedItem.type)) {
      <div class="mt-15">
        <div>
          <h3 class="text-s mb-10">Title:</h3>
          <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
        </div>
        <label>
          <span class="text-s"> Required </span>
          <input type="checkbox" name="enabled-toggle" class="toggle small" [(ngModel)]="isRequired" />
        </label>
      </div>
    }

    <div>
      <h4>Enable when:</h4>
      @for (definition of enableWhen; track $index) {
        <div class="enable-when-definition">
          <cc-select-field
            id="question-select"
            [options]="enableWhenQuestionsFiltered"
            label="Question"
            [(selected)]="definition.question"
          >
          </cc-select-field>
          <cc-select-field
            id="question-operator"
            [options]="enableOperators"
            label="Operator"
            [(selected)]="definition.operator"
          >
          </cc-select-field>
          <cc-input-field placeholder="Answer" [(value)]="definition.answerString!"></cc-input-field>
          <button class="btn-warning small" (click)="enableWhen.splice($index, 1)">Delete</button>
        </div>
      }
      <button class="btn-secondary small" (click)="enableWhen.push({ question: '', operator: '', answerString: '' })">
        Add definition
      </button>
    </div>

    <button class="btn-secondary small prop-btn" (click)="onSubmit()" type="submit">Speichern</button>
    <button class="btn-secondary small prop-btn" type="button" (click)="deleteOption(selectedItem)">Löschen</button>
  </div>
}
